import React from "react"
import styled from "@emotion/styled"
import { Link, graphql } from "gatsby"
import { FileText } from "react-feather"

import Seo from "../components/seo"
import BreadCrumbs from "../components/breadCrumbs"
import Title from "../components/title"

const Container = styled.div`
  margin: 16px 20px 96px;
  @media screen and (min-width: 1050px) {
    margin: 24px 0 96px;
  }
`

const Card = styled.div`
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1), 0px 10px 20px rgba(0, 0, 0, 0.15);
  display: flex;
  margin-bottom: 32px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #f5f7fa;
  padding: 3%;
  transition: all 300ms ease;
  & span {
    color: black;
  }
  @media screen and (min-width: 1050px) {
    margin-right: 24px;
    margin-bottom: 64px;
    flex-wrap: nowrap;
    width: 48%;
    z-index: 1;
  }
  &:hover {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`

const LinkContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  @media screen and (min-width: 1050px) {
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
  }
`

const CircolariPage = ({ data, location }) => {
  const posts = data.allMarkdownRemark.edges

  return (
    <>
      <Seo
        title="Circolari"
        description="Le circolari fornite dallo Studio commercialisti associati Benetti e Filippini"
      />
      <Container>
        <BreadCrumbs location={location} />
        <Title content="Circolari" />
        <LinkContainer>
          {posts.map(({ node }, index) => {
            const title = node.frontmatter.title
            return (
              <Card key={title}>
                <Link
                  style={{ boxShadow: `none` }}
                  to={`/${node.frontmatter.title}`}
                  key={index}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      padding: "10%",
                      flex: "1",
                    }}
                  >
                    <FileText size="72" color="#616E7C" />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flex: "2",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <span style={{ fontWeight: "600" }}>{title}</span>
                  </div>
                </Link>
              </Card>
            )
          })}
        </LinkContainer>
      </Container>
    </>
  )
}

export default CircolariPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`
